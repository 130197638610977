import Sales from './sales/sales.js';
import Scanning from './scanning/scanning.js';
import template from './eventhome.html';
import betaTooltipTemplate from './beta-tooltip.html';
import './eventhome.less';
import { copyToClipboard } from '../../dashboard';

export default angular.module('eventix.dashboard.home.event', [Sales, Scanning])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.home.event', {
            url: '/event/:guid',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'eventHomePage'
                }
            },
            resolve: /*@ngInject*/ {
                event: function($transition$, events) {
                    let event = _.find(events, {guid: $transition$.params().guid});

                    return event.$queryEventDate()
                        .then(dates => {
                            event.dates = dates;
                            event.start = _.get(_.minBy(dates, d => moment(d.start).format('X')), 'start', '');
                            event.end = _.get(_.maxBy(dates, d => moment(d.end).format('X')), 'end', '');
                            let daysUntilStart = moment(event.start).diff(moment(), 'days');
                            let daysUntilEnd = moment(event.end).diff(moment(), 'days');
                            event.daysUntilStart = isNaN(daysUntilStart) ? null : daysUntilStart;
                            event.daysUntilEnd = isNaN(daysUntilEnd) ? null : daysUntilEnd;
                            return event;
                        });
                },
                company: function(Company) {
                    return Company.getMe();
                }
            }
        }).state('eventix.dashboard.home.event.sales', {
            url: '/sales'
        }).state('eventix.dashboard.home.event.scanning', {
            url: '/scanning'
        });
    })
    .component('eventHomePage', {
        bindings: {
            events: '<',
            event: '<',
            company: '<'
        },
        controller: EventHomeController,
        templateUrl: template
    }).name;

function EventHomeController($state, $scope, $timeout, SweetAlert, $templateCache, $translate, Role) {
    const $ctrl = this;

    const EVENT_CONFIGURATION_TIMER = 5000;

    let progressii = {sales: 0.1667, scanning: 0.5};

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.betaTooltip = betaTooltipTemplate;
    $ctrl.eventProgressComplete = {sales: false, scanning: false};
    $ctrl.viewProgress = 'sales';
    $ctrl.eventHasDates = false;
    $ctrl.eventWhen = {days: {start: 0, end: 0}, seconds: {start: 0, end: 0}, multiday: false};
    $ctrl.hasBankDetails = false;

    $ctrl.copyToClipboard = copyToClipboard;



    $scope.$watch(function() {
        return $state.$current.name;
    }, function(newVal) {
        let progress = newVal.replace('eventix.dashboard.home.event', '').replace('.', '');

        if (progress !== '')
            $ctrl.viewProgress = progress;
    });

    $ctrl.$onInit = function() {
        $ctrl.newDashboardEventLink = NEW_DASHBOARD_ROOT + 'events/' + $ctrl.event.guid;

        $ctrl.eventHasDates = (_.size(_.get($ctrl.event, 'dates', [])) > 0);

        $ctrl.hasBankDetails = $ctrl.company.bank_account_number && $ctrl.company.bank_account_name;

        if (!$ctrl.eventHasDates) {
            let timer = $timeout(() => $state.go('eventix.dashboard.wizard', {guid: $ctrl.event.guid}), EVENT_CONFIGURATION_TIMER);

            SweetAlert.swal({
                title: $translate.instant('models.event.configuration'),
                text: $translate.instant('models.event.home.error.configuration'),
                type: 'error',
                timer: EVENT_CONFIGURATION_TIMER,
                cancelButtonText: $translate.instant('models.event.action.manage', {count: 1}),
                showConfirmButton: false,
                showCancelButton: true,
                closeOnConfirm: false,
                closeOnCancel: true
            }, () => {
                $timeout.cancel(timer);
                $state.go('eventix.dashboard.wizard', {guid: $ctrl.event.guid});
            });
        }

        $ctrl.eventWhen.days.start = moment($ctrl.event.start).startOf('day').diff(moment().startOf('day'), 'days');
        $ctrl.eventWhen.days.end = moment($ctrl.event.end).startOf('day').diff(moment().startOf('day'), 'days');
        $ctrl.eventWhen.seconds.start = moment($ctrl.event.start).diff(moment(), 'seconds');
        $ctrl.eventWhen.seconds.end = moment($ctrl.event.end).diff(moment(), 'seconds');
        $ctrl.eventWhen.multiday = ($ctrl.eventWhen.days.end - $ctrl.eventWhen.days.start) > 0;

        if ($ctrl.eventWhen.days.start > 0) {
            // Future
            $ctrl.setEventProgress('sales');
        } else if ($ctrl.eventWhen.days.start <= 0 && $ctrl.eventWhen.days.end >= 0) {
            // Present
            $ctrl.setEventProgress('scanning');
        } else if ($ctrl.eventWhen.days.end < 0) {
            // Past
            // Summary removed, will never be build -> redirect to sales
            $ctrl.setEventProgress('sales');
        } else {
            // Impossible state
            console.error('Impossible state [eventix.dashboard.home.event: progress]');
        }

        if ($state.includes('eventix.dashboard.home.event.*'))
            $ctrl.viewProgress = _.last($state.$current.name.split('.'));
    };

    $ctrl.setEventProgress = function(progress) {
        $ctrl.eventProgress = $ctrl.viewProgress = progress;

        // ???
        _.reduce($ctrl.eventProgressComplete, function(seen, value, key) {
            seen = seen || (key === progress);

            $ctrl.eventProgressComplete[key] = (!seen || key === progress);

            return seen;
        }, false);
    };

    $ctrl.eventProgressPercentage = function() {
        return progressii[$ctrl.eventProgress] * 100 + '%';
    };
}
